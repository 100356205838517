import { json } from '@remix-run/cloudflare';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import { Toaster } from 'react-hot-toast';

import {
  GoogleTagManagerNoScript,
  CloudflareInsights,
  ExposeAppConfig,
} from '-/analytics';
import { GoogleTagManagerStorefrontDataLayer } from '-/analytics/GoogleTagManager/GoogleTagManagerStorefrontDataLayer';
import { useDirection } from '-/i18n';
import { getSession } from '-/leap-auth';
import { i18n } from '~/i18n.server';
import OneTrustConsent from '~/storefront/components/OneTrustConsent';
import { getCreatorId } from '~/storefront/services/site.server';

import type {
  LoaderFunctionArgs,
  LoaderFunction,
  MetaFunction,
} from '@remix-run/cloudflare';
import type { Env } from '~/env';

export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  const { session, commitSession } = await getSession(args);
  const locale = await i18n.getLocale(args.request);
  const creatorId = await getCreatorId(args);

  const env = args.context.env as Env;
  const data = {
    appConfig: {
      // * WARNING *: Do not expose any sensitive variables here since it is included in the bundles
      googleAnalyticsId: env.GTM_ID,
      sentryDsn: env.SENTRY_DSN,
      newRelicLicenseKey: env.NEW_RELIC_LICENSE_KEY,
      newRelicApplicationId: env.NEW_RELIC_APP_ID,
      cfBeacon: env.CF_BEACON,
      remixApp: env.REMIX_ENV,
      oneTrustId: env.ONE_TRUST_ID,
      // * END WARNING *
    },
    locale,
    creatorId: creatorId,
  };

  return json(data, {
    headers: {
      'Set-Cookie': (await commitSession(session)) || '',
    },
  });
};

export const meta: MetaFunction = () => {
  return [{ title: 'Make The Leap' }];
};

interface LoaderData {
  appConfig: {
    googleAnalyticsId: string;
    sentryDsn: string;
    newRelicLicenseKey: string;
    newRelicApplicationId: string;
    cfBeacon: string;
    remixApp: string;
    oneTrustId?: string;
  };
  toastMessage?: any;
  locale: string;
  creatorId?: string;
}

export default function App() {
  const { appConfig, locale, creatorId } = useLoaderData<LoaderData>();
  const dir = useDirection();

  /*
    save onetrust's active cookie perferences
      C0001 - Necessary
      C0002 - Performance
      C0003 - Functional
      C0004 - Targeting
      C0005 - Social Media
      https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9?language=en_US

    If you want to add a new cookie, ensure it only gets set if the user has
    approved it through OneTrust.
  */
  // const [permittedCookieGroups, setPermittedCookieGroups] = useState('');
  // useEffect(() => {
  //   if (typeof OnetrustActiveGroups !== 'undefined') {
  //     setPermittedCookieGroups(OnetrustActiveGroups);
  //   }
  // }, []);

  return (
    <html lang={locale} dir={dir} className="leading-normal h-full w-full">
      <head>
        {/*
          Google Tag Manager Requirement #1
          This helps to ensure that your Tag Manager
          configuration is available and ready when the
          rest of the page loads.
        */}
        {appConfig.googleAnalyticsId && (
          <GoogleTagManagerStorefrontDataLayer
            googleAnalyticsId={appConfig.googleAnalyticsId}
            creatorId={creatorId}
          />
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <OneTrustConsent oneTrustId={appConfig.oneTrustId} />
        <Meta />
        <Links />
        <ExposeAppConfig appConfig={appConfig as AppConfig} />
      </head>
      <body className="antialiased h-full bg-body text-text-primary font-theme-body font-theme-body-weight">
        {/* Google Tag Manager Requirement #2
              - Track visitors who have disabled JavaScript on their browser
              - Or you wish to verify the ownership of the website in the Google
                Search Console by choosing the “Verify with Google Tag Manager” option.
            */}
        {appConfig.googleAnalyticsId && (
          <GoogleTagManagerNoScript id={appConfig.googleAnalyticsId} />
        )}
        <Toaster position="top-right" />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <CloudflareInsights cfBeacon={appConfig.cfBeacon} />
        <LiveReload />
      </body>
    </html>
  );
}
